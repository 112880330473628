<template>
  <div class="sort-second">
    <div class="sort-second-opr">
      <p>
        <span class="sort-second-title">二级分组管理</span> <span class="sort-second-info">{{sort.name}}</span></p>
      <div>
        <el-button type="text" @click="handleNewGroup({})"><i class="el-icon-circle-plus-outline"></i> 新增分组</el-button>
        <el-button type="text" @click="handleNewSort({})"><i class="el-icon-circle-plus-outline"></i> 新增分类</el-button>
      </div>
    </div>

    <div class="sort-second-list">
      <div class="list" v-if="sort.children && sort.children.length > 0">
        <div class="list-item" v-for="(group,idx) in sort.children" :key="idx">
          <p>
            <i class="iconfont icon-yanjing_xianshi" v-if="group.showName === 2"></i>
            <i class="iconfont icon-yanjing_yincang" v-else></i>
            <span class="list-item-title">{{group.name}}</span>
            <el-button type="text" size="mini" style="margin-left: 8px"
                       @click="handleNewGroup(group)"><i class="el-icon-edit"></i> 编辑
            </el-button>

            <el-button type="text" size="mini" style="margin-left: 8px"
                       @click="handleDeleteGroup(idx)"><i class="el-icon-delete"></i> 删除
            </el-button>
          </p>
          <div class="list-item-content" v-for="(sort, _idx) in group.children" :key="_idx">
            <div class="content">
              <img :src="sort.thumb"/>
              <p>{{sort.name}}</p>
              <div class="delete-remark">
                <div style="margin-top: 36%">
                  <el-button type="primary"
                             @click="handleNewSort(sort)"
                             size="mini" icon="el-icon-edit" circle></el-button>
                  <el-button type="danger" size="mini" icon="el-icon-delete"
                             @click="handleDeleteSort(sort, idx, _idx)" circle></el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <empty-tip v-else><p class="yk-tip">暂无分组,点击上方【新增分组】进行分组新增</p></empty-tip>
      <div class="remark" v-show="showType">

        <template v-if="showType  === 'sort'">
          <div class="edit" v-if="oprType === 'edit'">
            <el-form size="small" label-width="88px">
              <el-form-item>
                <span class="yk-label__require" slot="label">分类名称：</span>
                <el-input v-model="seSort.name" maxlength="4" show-word-limit/>
              </el-form-item>

              <el-form-item>
                <span class="yk-label__require" slot="label">所属分组：</span>
                <el-select v-model="seSort.parentUuid">
                  <el-option v-for="group in sort.children" :key="group.uuid" :label="group.name" :value="group.uuid">
                    {{group.name}}
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <span class="yk-label__require" slot="label">排序：</span>
                <el-input v-model.number="sort.sort"/>
              </el-form-item>

              <el-form-item label="封面图：">
                <span class="yk-label__require" slot="label">封面图：</span>
                <material-manager
                  :limit="1" v-model="seSort.thumb"
                  @change="(url) => {seSort.thumb = url}"
                  :width="'80px'" :height="'80px'" :preview="false"></material-manager>
                <p class="yk-tip">建议尺寸：80*80</p>
              </el-form-item>

              <el-form-item label="跳转链接：">
                <span class="yk-label__require" slot="label">跳转链接：</span>
                <el-select v-model="sortType">
                  <el-option v-for="opt in opts" :key="opt.type" :label="opt.name" :value="opt.type">{{opt.name}}
                  </el-option>
                </el-select>
                <el-input
                  v-model="seSort.url"
                  v-if="sortType ==='define'"
                  style="margin-top: 12px" placeholder="请输入需要跳转链接"/>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="showType=''" plain>取消</el-button>
                <el-button type="primary" @click="handleSortCommit">保存</el-button>
              </el-form-item>
            </el-form>
          </div>

          <div class="delete-confirm" v-if="oprType === 'delete'">
            <div class="delete-confirm-title">
              <span class="name">删除提示</span>
              <span class="font el-icon-close" @click="showType=''"></span>
            </div>
            <div class="delete-confirm-content">删除【<b>{{selectItem.name}}</b>】会导致当前分类商品无法被分类检索，是否删除</div>
            <div style="text-align: center">
              <el-button size="mini" @click="showType=''" plain>取消</el-button>
              <el-button type="danger" size="mini" @click="$emit('delete', selectItem)">确定</el-button>
            </div>
          </div>
        </template>

        <template v-if="showType === 'group'">
          <div class="edit" v-if="oprType === 'edit'">
            <el-form size="small" label-width="88px">
              <el-form-item>
                <span class="yk-label__require" slot="label">分组名称：</span>
                <el-input v-model="group.name" maxlength="4" show-word-limit/>
              </el-form-item>

              <el-form-item>
                <span class="yk-label__require" slot="label">排序：</span>
                <el-input v-model.number="group.sort"/>
              </el-form-item>

              <el-form-item>
                <span class="yk-label__require" slot="label">名称展示：</span>
                <el-switch v-model="group.showName"
                           active-text="显示" inactive-text="隐藏"
                           :active-value="2" :inactive-value="1"/>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="() => {showType='';oprType=''}" plain>取消</el-button>
                <el-button type="primary" @click="handleGroupCommit">保存</el-button>
              </el-form-item>
            </el-form>
          </div>

          <div class="delete-confirm" v-if="oprType === 'delete'">
            <div class="delete-confirm-title">
              <span class="name">删除提示</span>
              <span class="font el-icon-close" @click="showType=''"></span>
            </div>
            <div class="delete-confirm-content">是否删除【<b>{{selectItem.name}}</b>】</div>
            <div style="text-align: center">
              <el-button size="mini" @click="showType=''" plain>取消</el-button>
              <el-button type="danger" size="mini"
                         @click="handleDelete(sort)">确定
              </el-button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {fillObj, checkObj} from "@/utils/check";

  export default {
    name: '',
    components: {},
    props: {
      sort: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    mounted: function () {
    },
    data: function () {
      return {
        opts: [
          {
            type: 'detail',
            value: '/detail',
            name: '分类详情页面',
          },
          {
            type: 'define',
            value: '',
            name: '自定义页面',
          }
        ],
        grpIdx: 0,
        sortType: 'detail',

        group: {
          uuid: '',
          name: '',
          showName: 2,
          showSlide: 1,
          showGoods: 1,
          parentUUID: '',
          sort: 1,
        },
        seSort: {},
        oprType: '',
        selectItem: {
          type: '',
          grpIdx: 0,
          sortIdx: 0,
        },
        showType: '',
      }
    },
    methods: {
      handleNewGroup(group) {
        this.group = {
          uuid: '',
          name: '',
          showName: 2,
          showSlide: 1,
          showGoods: 1,
          parentUUID: '',
          sort: 1,
        }
        fillObj(this.group, group)
        this.showType = 'group'
        this.oprType = 'edit'
      },

      handleNewSort(sort) {
        this.seSort = {
          uuid: '',
          name: '',
          showName: 2,
          showSlide: 1,
          showGoods: 1,
          parentUuid: '',
          url: '',
          thumb: '',
          sort: 1,
        }
        fillObj(this.seSort, sort)
        let t = this.opts.find((item) => {
          return item.value === sort.url
        })

        if (t) {
          this.sortType = t.type
        } else {
          this.sortType = `detail`
        }
        this.showType = 'sort'
        this.oprType = 'edit'
      },
      handleDeleteGroup(grpIdx) {
        this.selectItem = {
          name: this.sort.children[grpIdx].name,
          type: `group`,
          grpIdx: grpIdx,

        }
        this.showType = 'group'
        this.oprType = 'delete'
      },

      handleDelete() {
        let v = this.selectItem
        if (v.type === 'group' && this.sort.children[v.grpIdx].children && this.sort.children[v.grpIdx].children.length > 0) {
          this.$message.warning('请先移除子级分类信息')
          return
        }
        this.$emit('delete', v)
      },

      handleDeleteSort(sort, grpIdx, sortIdx) {
        this.selectItem = {
          name: sort.name,
          type: `sort`,
          grpIdx: grpIdx,
          sortUuid: sort.uuid,
          sortIdx: sortIdx,
        }
        this.showType = 'sort'
        this.oprType = 'delete'
      },

      hidden() {
        this.showType = ''
      },

      handleGroupCommit() {
        let check = checkObj(this.group, [
          {value: "name", message: "请填写分组名称"},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        this.group.parentUuid = this.sort.uuid
        this.$emit('commit', this.group)
      },

      handleSortCommit() {
        this.seSort.url = this.sortType !== 'define' ?
          this.opts.find((item) => {
            return item.type === this.sortType
          }).value : this.seSort.url
        let check = checkObj(this.seSort, [
          {value: "name", message: "请填写分组名称"},
          {value: "url", message: "请选取跳转链接"},
          {value: "sort", message: "请填写排序"},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        this.$emit('commit', this.seSort)
      }
    },
  }
</script>

<style>

</style>
