<template>
  <el-form size="small" label-width="80px" class="sort-form">
    <el-form-item>
      <span class="yk-label__require" slot="label">分类名称：</span>
      <el-input v-model="info.name" maxlength="8" show-word-limit/>
    </el-form-item>

    <el-form-item >
      <span class="yk-label__require" slot="label">排序：</span>
      <el-input v-model.number="info.sort" maxlength="3" placeholder="数字越小越靠前，最小为1" show-word-limit/>
    </el-form-item>

    <el-form-item label="图标：" v-if="show.iconShowSelect">
      <el-select v-model="sort.icon">
        <el-option v-for="item in icons"
                   :key="item.icon"
                   :label="item.name"
                   :value="item.icon">
          <p class="goods-container-sort"><span>{{item.name}}</span>
            <svg class="mp" aria-hidden="true" v-if="item.icon">
              <use :xlink:href="'#'+item.icon"/>
            </svg>
          </p>
        </el-option>

      </el-select>
    </el-form-item>

    <el-form-item label="轮播图：" v-if="show.slideShowSelect || info.showSlide === 1">
      <el-switch v-model="info.showSlide"
                 active-text="启用" inactive-text="关闭"
                 :active-value="2" :inactive-value="1"/>

      <div v-if="info.showSlide === 2" style="margin-top: 8px;margin-bottom: 8px;">
        <material-manager
          :limit="4" v-model="info.slides"
          @change="(url) => {info.slides = url}"
          :width="'80px'" :height="'80px'" :preview="false"></material-manager>
        <p class="yk-tip">尺寸：750*300像素，小于1M，支持jpg、png、jpeg格式</p>
      </div>
    </el-form-item>

    <el-form-item v-if="!show.goodsShowSelect">
      <span class="yk-label__require" slot="label">展示内容：</span>
      <el-switch v-model="info.showGoods"
                 @change="$emit('change', info)"
                 active-text="下级分类" inactive-text="商品列表"
                 :active-value="1" :inactive-value="2"/>
    </el-form-item>

    <el-form-item>
      <slot name="cancel"></slot>
      <el-button type="primary" @click="handleCommit">
        <slot>提交</slot>
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import {fillObj, checkObj} from "@/utils/check";

  export default {
    name: '',
    props: {
      show: {
        type: Object,
        default: () => {
          return {}
        }
      },
      sort: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    watch: {
      sort: {
        handler(v) {
          fillObj(this.info, v)
        },
        deep: true,
      }
    },
    mounted: function () {
      fillObj(this.info, this.sort)
    },
    data: function () {
      return {
        info: {
          uuid: '',
          name: '',
          icon: '',
          showGoods: 2,
          showName: 2,
          showSlide: 2,
          sort: 0,
          slides: []
        },
        icons: [
          {name: '无图标', icon: ''},
          {name: '木瓜', icon: 'xm-mugua'},
          {name: '草莓', icon: 'xm-caomei'},
          {name: '猕猴桃', icon: 'xm-mihoutao'},
          {name: '梨', icon: 'xm-li'},
          {name: '西瓜', icon: 'xm-xigua'},
          {name: '石榴', icon: 'xm-shiliu'},
          {name: '胡萝卜', icon: 'xm-huluobu'},
          {name: '芒果', icon: 'xm-mangguo'},
          {name: '橘子', icon: 'xm-juzi'},
          {name: '香蕉', icon: 'xm-xiangjiao'},
          {name: '樱桃', icon: 'xm-yingtao'},
        ],
      }
    },
    methods: {
      handleCommit() {
        let check = checkObj(this.info, [
          {value: "name", message: "请填写分类名称"},
          {value: "sort", message: "排序最小为1"},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        this.$emit('commit', this.info)
      }
    },
  }
</script>

<style lang="scss">
  .sort-form {
    .el-form-item__label {
      font-size: 12px;
      padding-right: 4px;
    }

    .el-input {
      max-width: 160px;
    }

    .yk-tip {
      line-height: 1.7;
      border-bottom: 1px solid $content-border-color;
    }
  }

</style>
