<template>
  <div class="sort">
    <p class="page-title">商品分类</p>
    <el-row>
      <el-col :span="16" class="sort-tab">
        <div class="sort-tab-container">
          <div class="sort-tab-item" @click="handleTabClick(tab, idx)" :class="{active: idx === tabIdx}"
               v-for="(tab, idx) in tabs" :key="idx">
            {{tab.name}}
          </div>
        </div>

        <div class="sort-tab-detail">

          <div class="sort-tab-header">
            <p>
              <span class="title">{{tabs[tabIdx].name}}</span>

              <el-button type="text" @click="addVisible=true"><i class="el-icon-circle-plus"></i> 新增分类</el-button>
            </p>
            <p class="yk-tip tip">点击【<i class="el-icon-bottom"/>】栏目进行编辑，{{tabs[tabIdx].remark}}</p>
          </div>

          <div class="sort-container">
            <div class="sort-first" v-if="sorts.length > 0">
              <div class="sort-first-item" :class="{active: sortIdx === _idx}"
                   @click="handleSortClick(sort, _idx)"
                   v-for="(sort, _idx)  in sorts" :key="sort.uuid"> {{sort.name}}
                <svg class="mp" aria-hidden="true" v-if="sort.icon">
                  <use :xlink:href="'#'+sort.icon"/>
                </svg>
              </div>
            </div>

            <div class="sort-first-edit sort-edit" v-if="sorts.length > 0">
              <sort-form :show="tabs[tabIdx]"
                         :sort="sorts.length > 0?sorts[sortIdx]:{}"
                         @commit="handleSortUpdate"
                         @change="handleSortChange"
                         @cancel="addVisible = false">
                <el-button type="danger" slot="cancel" plain @click="handleDeleteSort">删除</el-button>
              </sort-form>
            </div>
            <div class="sort-edit sort-add" v-show="addVisible">
              <p class="page-title">新增分类</p>
              <sort-form :show="tabs[tabIdx]"
                         @commit="handleCreateSort">
                <el-button type="primary" slot="cancel" plain @click="addVisible = false">取消</el-button>
              </sort-form>
            </div>

            <empty-tip v-if="sorts.length === 0"><p class="yk-tip">暂无分类,请点击上方新增分类进行分类新增</p></empty-tip>
          </div>

        </div>
      </el-col>
      <el-col :span="8"
              v-if="tabs[tabIdx].shopUse === 2 &&sorts[sortIdx] &&sorts[sortIdx].showGoods === 1 && !addVisible">
        <second-sort
          :sort="sorts[sortIdx]"
          ref="second"
          @delete="handleGroupDelete"
          @commit="handleGroupCommit"></second-sort>

      </el-col>
    </el-row>
  </div>
</template>

<script>
  import SortForm from './sort-form'
  import SecondSort from './second-sort'
  import {fillObj} from "@/utils/check";

  export default {
    name: '',
    components: {
      SortForm,
      SecondSort
    },
    mounted: function () {
      this.loadSorts()
    },
    data: function () {
      return {
        tabIdx: 0,
        addVisible: false,
        tabs: [
          // {
          //   name: '普通商品',
          //   remark: '普通商品最大支持两级分类',
          //   goodsShowSelect: false,
          //   slideShowSelect: true,
          //   iconShowSelect: true,
          //   shopUse: 2,
          // },
          {
            name: '饮品类目',
            remark: '饮品类目最大支持一级分类, 【门店启用企业饮品类目时显示为当前类目列表】',
            goodsShowSelect: true,
            slideShowSelect: true,
            iconShowSelect: true,
            shopUse: 1,
          }
        ],

        sorts: [],
        sortIdx: 0,
      }
    },
    methods: {
      loadSorts() {
        this.$api('mkt.goods.sorts', {shopUse: this.tabs[this.tabIdx].shopUse}).then(res => {
          this.sorts = res || []
        })
      },
      handleCreateSort(sort) {
        sort.shopUse = this.tabs[this.tabIdx].shopUse
        this.$api('mkt.goods.sortCreate', {}, sort).then(res => {
          this.sorts.push({...sort, uuid: res})
          this.sorts.sort((m, n) => {
            return m.sort < n.sort
          })
          this.$message.success('创建成功')
          this.addVisible = false
        })
      },
      handleSortChange(info) {
        this.groups = this.sorts[this.sortIdx].children || []
        this.sorts[this.sortIdx].showGoods = info.showGoods
      },

      handleSortClick(sort, idx) {
        this.sortIdx = idx
      },

      handleDeleteSort() {
        if (this.sorts[this.sortIdx].children && this.sorts[this.sortIdx].children.length > 0) {
          this.$message.warning('请先移除子级分类信息')
          return
        }
        let vue = this
        this.$confirm(`此操作将会删除【${this.sorts[this.sortIdx].name}】, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api('mkt.goods.sortDelete', {uuid: vue.sorts[vue.sortIdx].uuid}).then(() => {
            vue.sorts.splice(this.sortIdx, 1)
            vue.sortIdx = 0
            vue.$message.success('删除成功')
          })
        })
      },
      handleSortUpdate(sort) {
        this.$api('mkt.goods.sortUpdate', {}, sort).then(() => {
          fillObj(this.sorts[this.sortIdx], sort)
          this.sorts.sort((m, n) => {
            return m.sort - n.sort
          })
          this.sortIdx = this.sorts.findIndex((i) => {
            return i.uuid === sort.uuid
          })
          this.$message.success('更新成功')
        })
      },

      handleGroupCommit(v) {
        v.shopUse = this.tabs[this.tabIdx].shopUse
        if (v.uuid) {
          this.$api('mkt.goods.sortUpdate', {}, v).then(() => {
            this.loadSorts()
            this.$refs.second.hidden()
            this.$message.success('更新成功')
          })
        } else {
          this.$api('mkt.goods.sortCreate', {}, v).then(() => {
            this.loadSorts()
            this.$refs.second.hidden()
            this.$message.success('创建成功')
          })
        }

      },

      handleGroupDelete(v) {
        let {type, grpIdx, sortUuid, sortIdx} = v
        let uuid = this.sorts[this.sortIdx].children[grpIdx].uuid
        if (type === 'sort') {
          uuid = sortUuid
        }
        this.$api('mkt.goods.sortDelete', {uuid: uuid}).then(() => {
          if (type === 'sort') {
            this.sorts[this.sortIdx].children[grpIdx].children.splice(sortIdx, 1)
          } else {
            this.sorts[this.sortIdx].children.splice(grpIdx, 1)
          }
          this.$refs.second.hidden()
          this.$message.success('删除成功')
        })
      },

      handleTabClick(tab, idx) {
        this.tabIdx = idx
        this.loadSorts()
      },
    },
  }
</script>

<style lang="scss">
  .sort {
    @include container();

    $sort-height: 584px;
    $sort-se-height: 480px;

    .page-title {
      margin-bottom: $middle-space;
    }

    &-tab {
      display: flex;
      min-height: $sort-height;
      border: 1px solid $content-border-color;
      border-radius: 4px;

      &-container {
        padding: 24px 0 8px 8px;
        overflow-y: scroll;
        width: 96px;
        background: $content-border-color;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &-item {
        text-align: center;
        margin-left: 12px;
        margin-bottom: 4px;
        padding: 0 10px;
        height: 40px;
        box-sizing: border-box;
        line-height: 40px;
        display: inline-block;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }

      &-item:hover, .active {
        background: white;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        color: $theme-color;
      }

      &-detail {
        border-left: none;
        flex: 1;
        height: 100%;
        padding: $middle-space;
        padding-bottom: 0;


        .sort-tab-header {
          height: 66px;
        }

        .tip {
          padding-bottom: $small-space;
          border-bottom: 1px solid $content-border-color;
        }

        .title {
          @include font-medium();
          margin-right: $middle-space;
        }

        .sort-container {
          min-height: $sort-se-height;
          display: flex;
          position: relative;
        }


        .sort-first {
          border-right: 1px solid $content-border-color;
          width: 88px;
          height: 100%;
          min-height: $sort-se-height;
          text-align: center;
          padding-top: $small-space;
          overflow-y: scroll;

          &-item {
            padding: $small-space 0;
            font-weight: 500;
            cursor: pointer;
          }

          &-item:hover, &-item.active {
            color: $theme-color;
          }

          &-edit {
            margin-top: $small-space;
          }
        }

        .sort-edit {
          flex: 1;
          padding: $middle-space;

        }

        .sort-add {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 99;
          background-color: white;
        }


      }

    }

    &-second {
      height: $sort-height;
      border-radius: 4px;
      border: 1px solid $content-border-color;
      padding: 16px $middle-space;
      padding-top: 19px;
      margin-left: $middle-space;

      &-opr {
        padding-left: 16px;
        border-bottom: 1px solid $content-border-color;
      }

      &-title {
        font-size: 16px;
        color: $color-black;
        font-weight: 600;
      }

      &-info {
        font-size: 13px;
      }

      &-list {
        position: relative;
        height: $sort-se-height;
        padding-top: $small-space;

        .list {
          height: 100%;
          overflow-y: scroll;

          &-item {
            &-title {
              @include font-medium-s();
              color: $color-black;
              font-weight: 600;
              margin: $small-space 0;
            }

            &-content {
              width: 33.3%;
              float: left;
              padding: 0 4px;
              margin-bottom: 4px;

              .content {
                text-align: center;
                position: relative;
                cursor: pointer;
                padding-top: $small-space;
                padding-bottom: 2px;
                border-radius: 4px;

                img {
                  max-width: 70px;
                }

                p {
                  font-size: 12px;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-clamp: 1;
                  -webkit-line-clamp: 1;
                }

                .delete-remark {
                  width: 100%;
                  height: 100%;
                  background: $remark-bg-color;
                  text-align: center;
                  position: absolute;
                  border-radius: 4px;

                  top: 0;
                  left: 0;
                  z-index: 90;
                  display: none;

                  p:first-children {
                    margin-top: 16%;
                  }

                  p {
                    display: inline-block;
                    margin-top: 12%;
                  }


                  .delete {
                    color: $brand-danger;
                  }
                }
              }

              .content:hover {
                background: #f3f3f3;

                .delete-remark {
                  display: block;
                }
              }

            }
          }

          &-item:after {
            display: block;
            content: " ";
            clear: both;
          }
        }

        .remark {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.38);
          width: 100%;
          height: 100%;
          border-bottom: 1px solid $content-border-color;
          z-index: 99;

          .el-input {
            max-width: 160px;
          }

          .el-form-item__label {
            font-size: 12px;
            padding-right: 4px;
          }

          .delete-confirm {
            background: white;
            border-radius: 8px;
            height: 180px;
            width: 90%;
            margin: auto;
            margin-top: 36%;

            &-title {
              padding-top: 8px;
              padding-bottom: 8px;
              height: 48px;
              line-height: 32px;
              text-align: center;
              position: relative;

              .name {
                font-size: 18px;
                display: inline-block;
              }

              .font {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
              }
            }

            &-content {
              padding: 0 $middle-space;
              @include font-little();
              height: 80px;
            }
          }

          .edit {
            margin-left: $middle-space;
            padding-top: 36px;
            height: 100%;
            background: white;

          }
        }
      }
    }

  }
</style>
